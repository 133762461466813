const $ = require('jquery');
import slick from 'slick-carousel';
import gsap from 'gsap';

$(function(){

'use strict';

/*******************************************
 * 
 * shop-Point
 * 
 *******************************************/

(function() {
    
    var elm = $('.shop-PointSlider');
    
    // init
    function init() {
        if(elm.length > 0){
            setup();
        }
    }

    //func
    function setup() {
        
        elm.find('[data-target=slider]').each(function(index_, elm_){
            var elm_slider = $(elm_);
            var elm_prev = elm_slider.parent().find('[data-target=slider_prev]');
            var elm_next = elm_slider.parent().find('[data-target=slider_next]');

            elm_slider.on('init', function(event, slick, currentSlide, nextSlide){
            });
            elm_slider.slick({
                dots: false,
                arrows: false,
                autoplay : false,
                fade : false,
                speed: 400,
                pauseOnHover: false,
                pauseOnFocus: false,
                touchMove: true,
                touchThreshold: 10,
                variableWidth: true,
                centerMode: true,
                infinite: false,
            });
            elm_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            });

            elm_prev.on('click',function(){
                elm_slider.slick('slickPrev');
            });
            elm_next.on('click',function(){
                elm_slider.slick('slickNext');
            });

        });
        
    }

    init();

})();

/*******************************************
 * 
 * shop-Point tab
 * 
 *******************************************/

(function() {

    // Elements
    var $elm = $('#shop-Point__button-Hair');

    // init
    function init() {
        $elm.on('click', function(event){

            $('.shop-Point__button.is_active').removeClass('is_active');
            $('#shop-Point__button-Hair').addClass('is_active');

            $('.shop-ShopInfomation__tabButton.is_active').removeClass('is_active');
            $('.shop-ShopInfomation__tabButton._js-Hair').addClass('is_active');

            $('.shop-ShopInfomation__tabPanel.is_active').removeClass('is_active');
            $('.shop-ShopInfomation__tabPanel._js-Hair').addClass('is_active');
            
            $('.shop-StyleLineup__tabButton.is_active').removeClass('is_active');
            $('.shop-StyleLineup__tabButton._js-Hair').addClass('is_active');

            $('.shop-StyleLineup__tabPanel.is_active').removeClass('is_active');
            $('.shop-StyleLineup__tabPanel._js-Hair').addClass('is_active');
                        
            $('.shop-Menu__tabButton.is_active').removeClass('is_active');
            $('.shop-Menu__tabButton._js-Hair').addClass('is_active');
            
            $('.shop-Menu__tabPanel.is_active').removeClass('is_active');
            $('.shop-Menu__tabPanel._js-Hair').addClass('is_active');

            $('.shop-Menu__reservation ._link.is_active').removeClass('is_active');
            $('.shop-Menu__reservation ._link._js-Hair').addClass('is_active');
        });
    }

    init();

})();


(function() {

    // Elements
    var $elm = $('#shop-Point__button-Nail');

    // init
    function init() {
        $elm.on('click', function(event){

            $('.shop-Point__button.is_active').removeClass('is_active');
            $('#shop-Point__button-Nail').addClass('is_active');

            $('.shop-ShopInfomation__tabButton.is_active').removeClass('is_active');
            $('.shop-ShopInfomation__tabButton._js-Nail').addClass('is_active');

            $('.shop-ShopInfomation__tabPanel.is_active').removeClass('is_active');
            $('.shop-ShopInfomation__tabPanel._js-Nail').addClass('is_active');
            
            $('.shop-StyleLineup__tabButton.is_active').removeClass('is_active');
            $('.shop-StyleLineup__tabButton._js-Nail').addClass('is_active');

            $('.shop-StyleLineup__tabPanel.is_active').removeClass('is_active');
            $('.shop-StyleLineup__tabPanel._js-Nail').addClass('is_active');
                        
            $('.shop-Menu__tabButton.is_active').removeClass('is_active');
            $('.shop-Menu__tabButton._js-Nail').addClass('is_active');
            
            $('.shop-Menu__tabPanel.is_active').removeClass('is_active');
            $('.shop-Menu__tabPanel._js-Nail').addClass('is_active');

            $('.shop-Menu__reservation ._link.is_active').removeClass('is_active');
            $('.shop-Menu__reservation ._link._js-Nail').addClass('is_active');
        });
    }

    init();

})();



(function() {

    // Elements
    var $elm = $('#shop-Point__button-Eyelash');

    // init
    function init() {
        $elm.on('click', function(event){

            $('.shop-Point__button.is_active').removeClass('is_active');
            $('#shop-Point__button-Eyelash').addClass('is_active');

            $('.shop-ShopInfomation__tabButton.is_active').removeClass('is_active');
            $('.shop-ShopInfomation__tabButton._js-Eyelash').addClass('is_active');

            $('.shop-ShopInfomation__tabPanel.is_active').removeClass('is_active');
            $('.shop-ShopInfomation__tabPanel._js-Eyelash').addClass('is_active');
            
            $('.shop-StyleLineup__tabButton.is_active').removeClass('is_active');
            $('.shop-StyleLineup__tabButton._js-Eyelash').addClass('is_active');

            $('.shop-StyleLineup__tabPanel.is_active').removeClass('is_active');
            $('.shop-StyleLineup__tabPanel._js-Eyelash').addClass('is_active');
                        
            $('.shop-Menu__tabButton.is_active').removeClass('is_active');
            $('.shop-Menu__tabButton._js-Eyelash').addClass('is_active');
            
            $('.shop-Menu__tabPanel.is_active').removeClass('is_active');
            $('.shop-Menu__tabPanel._js-Eyelash').addClass('is_active');

            $('.shop-Menu__reservation ._link.is_active').removeClass('is_active');
            $('.shop-Menu__reservation ._link._js-Eyelash').addClass('is_active');
        });
    }

    init();

})();


/*******************************************
 * 
 * shop-ShopInfomation tab
 * 
 *******************************************/

(function() {

    // Elements
    var $elm = $('.shop-ShopInfomation__tabButton._js-Hair');

    // init
    function init() {
        $elm.on('click', function(event){

            $('.shop-ShopInfomation__tabButton.is_active').removeClass('is_active');
            $('.shop-ShopInfomation__tabButton._js-Hair').addClass('is_active');

            $('.shop-ShopInfomation__tabPanel.is_active').removeClass('is_active');
            $('.shop-ShopInfomation__tabPanel._js-Hair').addClass('is_active');

        });
    }

    init();

})();


(function() {

    // Elements
    var $elm = $('.shop-ShopInfomation__tabButton._js-Nail');

    // init
    function init() {
        $elm.on('click', function(event){

            $('.shop-ShopInfomation__tabButton.is_active').removeClass('is_active');
            $('.shop-ShopInfomation__tabButton._js-Nail').addClass('is_active');

            $('.shop-ShopInfomation__tabPanel.is_active').removeClass('is_active');
            $('.shop-ShopInfomation__tabPanel._js-Nail').addClass('is_active');

        });
    }

    init();

})();


(function() {

    // Elements
    var $elm = $('.shop-ShopInfomation__tabButton._js-Eyelash');

    // init
    function init() {
        $elm.on('click', function(event){

            $('.shop-ShopInfomation__tabButton.is_active').removeClass('is_active');
            $('.shop-ShopInfomation__tabButton._js-Eyelash').addClass('is_active');

            $('.shop-ShopInfomation__tabPanel.is_active').removeClass('is_active');
            $('.shop-ShopInfomation__tabPanel._js-Eyelash').addClass('is_active');

        });
    }

    init();

})();


/*******************************************
 * 
 * shop-StyleLineup tab
 * 
 *******************************************/

(function() {

    // Elements
    var $elm = $('.shop-StyleLineup__tabButton._js-Hair');

    // init
    function init() {
        $elm.on('click', function(event){

            $('.shop-StyleLineup__tabButton.is_active').removeClass('is_active');
            $('.shop-StyleLineup__tabButton._js-Hair').addClass('is_active');

            $('.shop-StyleLineup__tabPanel.is_active').removeClass('is_active');
            $('.shop-StyleLineup__tabPanel._js-Hair').addClass('is_active');

        });
    }

    init();

})();



(function() {

    // Elements
    var $elm = $('.shop-StyleLineup__tabButton._js-Nail');

    // init
    function init() {
        $elm.on('click', function(event){

            $('.shop-StyleLineup__tabButton.is_active').removeClass('is_active');
            $('.shop-StyleLineup__tabButton._js-Nail').addClass('is_active');

            $('.shop-StyleLineup__tabPanel.is_active').removeClass('is_active');
            $('.shop-StyleLineup__tabPanel._js-Nail').addClass('is_active');

        });
    }

    init();

})();


(function() {

    // Elements
    var $elm = $('.shop-StyleLineup__tabButton._js-Eyelash');

    // init
    function init() {
        $elm.on('click', function(event){

            $('.shop-StyleLineup__tabButton.is_active').removeClass('is_active');
            $('.shop-StyleLineup__tabButton._js-Eyelash').addClass('is_active');

            $('.shop-StyleLineup__tabPanel.is_active').removeClass('is_active');
            $('.shop-StyleLineup__tabPanel._js-Eyelash').addClass('is_active');

        });
    }

    init();

})();

/*******************************************
 * 
 * shop-Menu tab
 * 
 *******************************************/

(function() {

    // Elements
    var $elm = $('.shop-Menu__tabButton._js-Hair');

    // init
    function init() {
        $elm.on('click', function(event){

            $('.shop-Menu__tabButton.is_active').removeClass('is_active');
            $('.shop-Menu__tabButton._js-Hair').addClass('is_active');

            $('.shop-Menu__tabPanel.is_active').removeClass('is_active');
            $('.shop-Menu__tabPanel._js-Hair').addClass('is_active');

            $('.shop-Menu__reservation ._link.is_active').removeClass('is_active');
            $('.shop-Menu__reservation ._link._js-Hair').addClass('is_active');

        });
    }

    init();

})();


(function() {

    // Elements
    var $elm = $('.shop-Menu__tabButton._js-Nail');

    // init
    function init() {
        $elm.on('click', function(event){

            $('.shop-Menu__tabButton.is_active').removeClass('is_active');
            $('.shop-Menu__tabButton._js-Nail').addClass('is_active');

            $('.shop-Menu__tabPanel.is_active').removeClass('is_active');
            $('.shop-Menu__tabPanel._js-Nail').addClass('is_active');

            $('.shop-Menu__reservation ._link.is_active').removeClass('is_active');
            $('.shop-Menu__reservation ._link._js-Nail').addClass('is_active');

        });
    }

    init();

})();



(function() {

    // Elements
    var $elm = $('.shop-Menu__tabButton._js-Eyelash');

    // init
    function init() {
        $elm.on('click', function(event){

            $('.shop-Menu__tabButton.is_active').removeClass('is_active');
            $('.shop-Menu__tabButton._js-Eyelash').addClass('is_active');

            $('.shop-Menu__tabPanel.is_active').removeClass('is_active');
            $('.shop-Menu__tabPanel._js-Eyelash').addClass('is_active');

            $('.shop-Menu__reservation ._link.is_active').removeClass('is_active');
            $('.shop-Menu__reservation ._link._js-Eyelash').addClass('is_active');

        });
    }

    init();

})();


/*******************************************
 * 
 * shop-Point_modal
 * 
 *******************************************/

$(function () {
    $('#shop-Point__openModal1').on('click', function(e){
        $('#shop-Point__modalArea1').fadeIn();
        e.preventDefault();
    });
    $('#shop-Point__closeModal1 , #shop-Point__modalBg1').on('click', function(){
      $('#shop-Point__modalArea1').fadeOut();
    });
});

$(function () {
    $('#shop-Point__openModal2').on('click', function(e){
        $('#shop-Point__modalArea2').fadeIn();
        e.preventDefault();
    });
    $('#shop-Point__closeModal2 , #shop-Point__modalBg2').on('click', function(){
      $('#shop-Point__modalArea2').fadeOut();
    });
});

$(function () {
    $('#shop-Point__openModal3').on('click', function(e){
        $('#shop-Point__modalArea3').fadeIn();
        e.preventDefault();
    });
    $('#shop-Point__closeModal3 , #shop-Point__modalBg3').on('click', function(){
      $('#shop-Point__modalArea3').fadeOut();
    });
});

$(function () {
    $('#shop-Point__openModal4').on('click', function(e){
        $('#shop-Point__modalArea4').fadeIn();
        e.preventDefault();
    });
    $('#shop-Point__closeModal4 , #shop-Point__modalBg4').on('click', function(){
      $('#shop-Point__modalArea4').fadeOut();
    });
});


$(function () {
    $('#shop-Point__openModal5').on('click', function(e){
        $('#shop-Point__modalArea5').fadeIn();
        e.preventDefault();
    });
    $('#shop-Point__closeModal5 , #shop-Point__modalBg5').on('click', function(){
      $('#shop-Point__modalArea5').fadeOut();
    });
});

/*******************************************
 *******************************************/
});