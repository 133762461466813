const $ = require('jquery');
import gsap from 'gsap';

$(function(){

'use strict';

/*******************************************
 * 
 *  sp menu panel
 * 
********************************************/

(function() {

    var elm_parent = $('.header-SpMenuPanel');
    var elm_buttons = $('.header-SpMenuPanel__heading-item');
    var elm_contents = $('.header-SpMenuPanel__body');
    
    // init
    function init() {
        if(elm_parent.length > 0){
            setup();
        }
    }

    //func
    function setup() {
        elm_buttons.find('[data-target=spPanel_shop]').eq(0).addClass('is-active');
        elm_contents.find('[data-target=spPanel_shop]').eq(0).addClass('is-active');
        elm_buttons.find('[data-target]').each(function(){
            $(this).on('click',function(){
                if(!$(this).hasClass('is-active')){
                    elm_buttons.find('.is-active[data-target]').removeClass('is-active');
                    elm_contents.find('.is-active[data-target]').removeClass('is-active');
                    $(this).addClass('is-active');
                    var target = $(this).attr('data-target');
                    elm_contents.find('[data-target='+target+']').eq(0).addClass('is-active');
                }
            });
        });



        /***********************************************
         *
         *  shoplist start
         *
         **********************************************/
        
            var data = window.global_menu["shop"];

            var htmlElement = $('<div></div>').addClass('AccordionMenuItems');

            $.each(data, function(index, val){
                var tmp_elm = $('<div></div>').addClass('AccordionMenuItems__item').append(
                    $('<div></div>').addClass('AccordionMenuItems__item-label').attr('onclick','').text(val.name)
                ).append(
                    $('<div></div>').addClass('AccordionMenuItems__item-inner').append(
                        $('<div></div>').addClass('ListMenuItems')
                    )
                );
                if('child' in val){
                    $.each(val.child, function(index, val2){

                        var push_item = function(brand_name, items){
                            var html = $('<div></div>').addClass('ListMenuItems__item').append(
                                $('<div></div>').addClass('ListMenuItems__title').text(brand_name)
                            ).append(
                                $('<div></div>').addClass('ListMenuItems__content').append(
                                    $('<ul></ul>')
                                )
                            );

                            $.each(items, function(index, item){
                                html.find('ul').append(
                                    $('<li></li>').append(
                                        $('<a></a>').attr('href', item.shop_url).text(item.name)
                                    )
                                );
                            });
                            
                            return html;
                        }

                        if('child' in val2){
                            tmp_elm.find('.ListMenuItems').append( push_item(val2.name, val2.child) );
                        }else{
                            tmp_elm.find('.ListMenuItems').append( push_item(val2.name, {val2}) );
                        }
                    });
                }
                htmlElement.append(tmp_elm);
            });

            htmlElement.find('.AccordionMenuItems__item').eq(0).addClass('is-active');

        /***********************************************
         *
         *  shoplist end
         *
         **********************************************/
        
        elm_contents.find('[data-target=spPanel_shop]').eq(0).html(htmlElement);
    }

    init();
})();

/*******************************************
********************************************/
});