const $ = require('jquery');

$(function(){

'use strict';

/*******************************************
 * 
 * anime classes
 * 
********************************************/

(function() {

    // element

    // var
    
    // init
    function init() {
        deviceObserver.$window.on( deviceObserver.events.Change, function(){
            setup();
        });
        setup();
    }

    //動作の設定
    function setup(){
        resetAnimeObjects();
        animeScript();
    }
    

    function resetAnimeObjects(){
        //アニメーションを最後まで進める
        for(var i=0;tlObjects.length>i;++i){
            tlObjects[i].pause(tlObjects[i].endTime());
            tlObjects[i].kill();
        }
        tlObjects = [];
    }

    init();

})();

/*******************************************
********************************************/
});