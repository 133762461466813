const $ = require('jquery');

/*******************************************
 * 
 * animeScript
 * ページや要素ごとの具体的なアニメの記述
 * 
********************************************/

$(function(){

'use strict';

var isDesktopWide = deviceObserver.isDesktopWide;
var isDesktop = deviceObserver.isDesktop;
var isTablet = deviceObserver.isTablet;
var isMobile = deviceObserver.isMobile;
var isMobileSE = deviceObserver.isMobileSE;

//setAnimation( _elm, _trigger, _beforeTween, _afterTween, _params);

window.animeScript = function(){

/*******************************************
 * 
 * top
 * 
********************************************/

(function() {

    if($('body.page-top').length){
        if( isDesktopWide || isDesktop ){
            $('.top-RecommendItems__bg-pc').each(function(index, parentTile){
                $(this).find('.SvgUse--tri_right_pc').each(function(){
                    setAnimation(this, parentTile, { x:200, delay:0 }, {offset: '-=400'});
                });
                $(this).find('.SvgUse--tri_left_pc').each(function(){
                    setAnimation(this, parentTile, { x:-200, delay:0 }, {offset: '-=400'});
                });
                
            });
        }
    }

})();

/*******************************************
********************************************/

}

});