const $ = require('jquery');
import slick from 'slick-carousel';
import gsap from 'gsap';

$(function(){

'use strict';

/*******************************************
 * 
 * info
 * 
 *******************************************/

(function() {
    
    var elm = $('.header-Info');
    
    // init
    function init() {
        if(elm.length > 0){
            setup();
        }
    }

    //func
    function setup() {
        
        elm.find('[data-target=slider]').each(function(index_, elm_){
            var elm_slider = $(elm_);
            var elm_prev = elm_slider.parent().find('[data-target=slider_prev]');
            var elm_next = elm_slider.parent().find('[data-target=slider_next]');

            elm_slider.on('init', function(event, slick, currentSlide, nextSlide){
            });
            elm_slider.slick({
                dots: false,
                arrows: false,
                autoplay : false,
                fade : true,
                speed: 0,
                pauseOnHover: false,
                pauseOnFocus: false,
                touchMove: false
            });
            elm_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                var slideItem = elm_slider.find('.header-Info__slider-item').eq(nextSlide);
                gsap.from(
                    slideItem.find('._date'), {
                        opacity: 0,
                        x: 10,
                        ease: "power4"
                    }
                ).duration(1.5).delay(0);
                gsap.from(
                    slideItem.find('._content'), {
                        opacity: 0,
                        x: 10,
                        ease: "power4"
                    }
                ).duration(1.5).delay(0.3);
            });

            elm_prev.on('click',function(){
                elm_slider.slick('slickPrev');
            });
            elm_next.on('click',function(){
                elm_slider.slick('slickNext');
            });

        });
        
    }

    init();

})();


/*******************************************
 * 
 * recommend
 * 
 *******************************************/

(function() {
    
    var elm = $('.top-RecommendTile');
    
    // init
    function init() {
        if(elm.length > 0){
            setup();
        }
    }

    //func
    function setup() {
        
        elm.find('[data-target=slider]').each(function(index_, elm_){
            var elm_slider = $(elm_);
            var elm_prev = elm_slider.parent().find('[data-target=slider_prev]');
            var elm_next = elm_slider.parent().find('[data-target=slider_next]');

            elm_slider.on('init', function(event, slick, currentSlide, nextSlide){
            });
            elm_slider.slick({
                dots: false,
                arrows: false,
                autoplay : false,
                fade : true,
                speed: 0,
                pauseOnHover: false,
                pauseOnFocus: false,
                touchMove: false
            });
            elm_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                var slideItem = elm_slider.find('.top-RecommendSlider__item').eq(nextSlide);
                gsap.from(
                    slideItem.find('.top-RecommendSlider__visual'), {
                        opacity: 0,
                        x: 10,
                        ease: "power4"
                    }
                ).duration(1.5).delay(0);
                gsap.from(
                    slideItem.find('.top-RecommendSlider__logo'), {
                        opacity: 0,
                        x: 10,
                        ease: "power4"
                    }
                ).duration(1.5).delay(0.3);
                gsap.from(
                    slideItem.find('.top-RecommendSlider__text'), {
                        opacity: 0,
                        x: 10,
                        ease: "power4"
                    }
                ).duration(1.5).delay(0.6);
            });

            elm_prev.on('click',function(){
                elm_slider.slick('slickPrev');
            });
            elm_next.on('click',function(){
                elm_slider.slick('slickNext');
            });

        });
        
    }

    init();

})();


/*******************************************
 * 
 * blog
 * 
 *******************************************/

(function() {
    
    var elm = $('.top-BlogSlider');
    
    // init
    function init() {
        if(elm.length > 0){
            setup();
        }
    }

    //func
    function setup() {
        
        elm.find('[data-target=slider]').each(function(index_, elm_){
            var elm_slider = $(elm_);
            var elm_prev = elm_slider.parent().find('[data-target=slider_prev]');
            var elm_next = elm_slider.parent().find('[data-target=slider_next]');

            elm_slider.on('init', function(event, slick, currentSlide, nextSlide){
            });
            elm_slider.slick({
                dots: false,
                arrows: false,
                autoplay : false,
                fade : false,
                speed: 400,
                pauseOnHover: false,
                pauseOnFocus: false,
                touchMove: true,
                touchThreshold: 10,
                variableWidth: true,
                centerMode: true,
                infinite: false,
            });
            elm_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            });

            elm_prev.on('click',function(){
                elm_slider.slick('slickPrev');
            });
            elm_next.on('click',function(){
                elm_slider.slick('slickNext');
            });

        });
        
    }

    init();

})();

/*******************************************
 * 
 * news
 * 
 *******************************************/

(function() {
    
    var elm = $('.top-NewsSlider');
    
    // init
    function init() {
        if(elm.length > 0){
            setup();
        }
    }

    //func
    function setup() {
        
        elm.find('[data-target=slider]').each(function(index_, elm_){
            var elm_slider = $(elm_);
            var elm_prev = elm_slider.parent().find('[data-target=slider_prev]');
            var elm_next = elm_slider.parent().find('[data-target=slider_next]');

            elm_slider.on('init', function(event, slick, currentSlide, nextSlide){
            });
            elm_slider.slick({
                dots: false,
                arrows: false,
                autoplay : false,
                fade : false,
                speed: 400,
                pauseOnHover: false,
                pauseOnFocus: false,
                touchMove: true,
                touchThreshold: 10,
                variableWidth: true,
                centerMode: true,
                infinite: false,
            });
            elm_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            });

            elm_prev.on('click',function(){
                elm_slider.slick('slickPrev');
            });
            elm_next.on('click',function(){
                elm_slider.slick('slickNext');
            });

        });
        
    }

    init();

})();

/*******************************************
 *******************************************/
});