const $ = require('jquery');
import gsap from 'gsap';

$(function(){

'use strict';

/*******************************************
 * 
 *  global listmenu
 * 
********************************************/

(function() {

    var elm_buttons = $('[data-target=listmenu]');
    var elm_wrap = $('.global-ListMenu');
    var elm_inner = $('.global-ListMenu__inner');
    
    var json_name_shop = "shop";

    // init
    function init() {
        if(elm_buttons.length > 0){
            setup();
        }
    }

    //func
    function setup() {
        elm_wrap.on('click',function(e){
            //子要素からのバブリングをはじく
            if(e.target===$(this)[0]){
                $('body').removeClass('is-listMenuOpened');
                elm_buttons.each(function(){
                    $(this).removeClass('is-active');
                });
                elm_inner.html('');
            }
        });
        elm_buttons.each(function(index_, elm_){
            var elm_button = $(elm_);
            elm_button.on('click', function(){
                
                var mode = elm_button.attr('data-src');
                var initialIndex = elm_button.attr('data-index');

                switch(mode){
                    case 'reserve':

                        var data = window.global_menu["shop"];

                        var htmlElement = $('<ul></ul>');
                        pushList(data, htmlElement, 'reserve_url');
                        
                        htmlElement.find('a').each(function(){
                            var href = $(this).attr('href');
                            if((href===undefined)||(href==='')){
                                $(this).addClass('is-disabled');
                            }
                        });

                        htmlElement.children('li').each(function(index, element){
                            gsap.from(
                                element, {
                                    opacity: 0,
                                    x: 10,
                                    ease: "power4"
                                }
                            ).duration(1.2).delay(0.2*index);
                        });
                        
                        elm_inner.html('');
                        elm_inner.append(htmlElement);
                        
                        break;
                    case 'instagram':

                        var data = window.global_menu["shop"];

                        var htmlElement = $('<ul></ul>');
                        pushList(data, htmlElement, 'instagram_url');
                        
                        htmlElement.find('a').each(function(){
                            var href = $(this).attr('href');
                            if((href===undefined)||(href==='')){
                                $(this).addClass('is-disabled');
                            }
                        });

                        htmlElement.children('li').each(function(index, element){
                            gsap.from(
                                element, {
                                    opacity: 0,
                                    x: 10,
                                    ease: "power4"
                                }
                            ).duration(1.2).delay(0.2*index);
                        });
                        
                        elm_inner.html('');
                        elm_inner.append(htmlElement);

                        break;
                    case 'shop':

                        var data = window.global_menu["shop"];

                        var htmlElement = $('<ul></ul>');
                        pushList(data, htmlElement, 'shop_url');
                        
                        htmlElement.find('a').each(function(){
                            var href = $(this).attr('href');
                            if((href===undefined)||(href==='')){
                                $(this).addClass('is-disabled');
                            }
                        });

                        htmlElement.children('li').each(function(index, element){
                            gsap.from(
                                element, {
                                    opacity: 0,
                                    x: 10,
                                    ease: "power4"
                                }
                            ).duration(1.2).delay(0.2*index);
                        });
                        
                        elm_inner.html('');
                        elm_inner.append(htmlElement);

                        break;
                    case 'lineup':

                        var data = window.global_menu["lineup"];

                        var htmlElement = $('<ul></ul>');
                        pushList(data, htmlElement, 'url');
                        
                        htmlElement.find('a').each(function(){
                            var href = $(this).attr('href');
                            if((href===undefined)||(href==='')){
                                $(this).addClass('is-disabled');
                            }
                        });

                        htmlElement.children('li').each(function(index, element){
                            gsap.from(
                                element, {
                                    opacity: 0,
                                    x: 10,
                                    ease: "power4"
                                }
                            ).duration(1.2).delay(0.2*index);
                        });

                        if(initialIndex){
                            htmlElement.children('li').eq(initialIndex-1).addClass('is-childOpened');
                        }

                        elm_inner.html('');
                        elm_inner.append(htmlElement);

                        break;
                    case 'recommend':

                        var data = window.global_menu["recommend"];

                        var htmlElement = $('<ul></ul>');
                        pushList(data, htmlElement, 'url');
                        
                        htmlElement.find('a').each(function(){
                            var href = $(this).attr('href');
                            if((href===undefined)||(href==='')){
                                $(this).addClass('is-disabled');
                            }
                        });

                        htmlElement.children('li').each(function(index, element){
                            gsap.from(
                                element, {
                                    opacity: 0,
                                    x: 10,
                                    ease: "power4"
                                }
                            ).duration(1.2).delay(0.2*index);
                        });
                        
                        elm_inner.html('');
                        elm_inner.append(htmlElement);

                        break;
                    case 'default':
                        break;
                }

                $('body').addClass('is-listMenuOpened');

                elm_buttons.each(function(){
                    $(this).removeClass('is-active');
                });
                $(this).addClass('is-active');
                
                return false;
            });
        });

    }

    function pushList(item, target, url_type_index){
        $.each(item, function(index, val){
            if('child' in val){
                var newTarget = $('<ul></ul>');
                target.append(
                    $('<li></li>').addClass('_hasChild').attr('onclick','').append(
                        $('<div></div>').text(val.name).on('click', function(){
                            var parentLi = $(this).parent('li');
                            var ul = $(this).next();
                            if(parentLi.hasClass('is-childOpened')){
                                parentLi.removeClass('is-childOpened');
                                parentLi.find('.is-childOpened').removeClass('is-childOpened');
                                ul.find('li').each(function(index, element){
                                    gsap.killTweensOf(element);
                                });
                            }else{
                                parentLi.addClass('is-childOpened');
                                ul.children('li').each(function(index, element){
                                    gsap.fromTo(
                                        element, {
                                            opacity: 0,
                                            x: 10
                                        }, {
                                            opacity: 1,
                                            x: 0,
                                            ease: "power4"
                                        }
                                    ).duration(1.2).delay(0.2*index);
                                });
                            }
                        })
                    ).append(newTarget)
                );
                pushList(val.child, newTarget, url_type_index);
            }else{
                target.append(
                    $('<li></li>').append(
                        $('<a></a>').text(val.name).attr('href', val[url_type_index])
                    )
                );
            }
        });
    }

    init();
})();

/*******************************************
********************************************/
});