const $ = require('jquery');
import gsap from 'gsap';

$(function(){

'use strict';

/*******************************************
 * 
 *  global modalMenu
 * 
********************************************/

(function() {

    var elm_buttons = $('[data-target=modalmenu]');
    var elm_wrap = $('.global-ModalMenu');
    var elm_closeButton = $('.global-ModalMenu__close');
    var elm_inner = $('.global-ModalMenu__inner');
    
    // init
    function init() {
        if(elm_buttons.length > 0){
            setup();
        }
    }

    //func
    function setup() {
        elm_wrap.on('click',function(e){
            //子要素からのバブリングをはじく
            if(e.target===$(this)[0]){
                $('body').removeClass('is-modalMenuOpened');
                elm_inner.html('');
            }
        });
        elm_closeButton.on('click',function(e){
            $('body').removeClass('is-modalMenuOpened');
            elm_inner.html('');
        });
        elm_buttons.each(function(index_, elm_){
            var elm_button = $(elm_);
            elm_button.on('click', function(){

                var mode = elm_button.attr('data-src');
                var initialIndex = elm_button.attr('data-index');
                
                switch(mode){
                    case 'shop':
                        
                        var data = window.global_menu["shop"];
                        
                        var htmlElement = $('<div></div>').addClass('AccordionMenuItems');

                        $.each(data, function(index, val){
                            var tmp_elm = $('<div></div>').addClass('AccordionMenuItems__item').append(
                                $('<div></div>').addClass('AccordionMenuItems__item-label').attr('onclick','').text(val.name)
                            ).append(
                                $('<div></div>').addClass('AccordionMenuItems__item-inner').append(
                                    $('<div></div>').addClass('ShopMenuItems')
                                )
                            );
                            if('child' in val){
                                $.each(val.child, function(index, val2){

                                    var push_item = function(brand_name, item){
                                        var html = $('<div></div>').addClass('ShopMenuItems__item').append(
                                            $('<div></div>').addClass('ShopMenuItems__image').append(
                                                $('<div></div>').addClass('_image').append(
                                                    $('<img>').attr('alt','').attr('src', item.thumb_url)
                                                )
                                            )
                                        ).append(
                                            $('<div></div>').addClass('ShopMenuItems__content').append(
                                                $('<div></div>').addClass('ShopMenuItems__content-heading').append(
                                                    $('<div></div>').addClass('_brand').text(brand_name)
                                                ).append(
                                                    $('<div></div>').addClass('_title').text(item.name)
                                                )
                                            ).append(
                                                $('<div></div>').addClass('ShopMenuItems__content-items').append(
                                                    $('<a></a>').addClass('_item').attr('href', item.shop_url).append(
                                                        $('<div></div>').addClass('_icon').append(
                                                            $('<div></div>').addClass('SvgUse').addClass('SvgUse--shop').attr('data-svg','shop')
                                                        )
                                                    ).append(
                                                        $('<div></div>').addClass('_name').text('店舗情報')
                                                    )
                                                ).append(
                                                    $('<a></a>').addClass('_item').attr('href', item.reserve_url).append(
                                                        $('<div></div>').addClass('_icon').append(
                                                            $('<div></div>').addClass('SvgUse').addClass('SvgUse--reserve').attr('data-svg','reserve')
                                                        )
                                                    ).append(
                                                        $('<div></div>').addClass('_name').text('予約')
                                                    )
                                                ).append(
                                                    $('<a></a>').addClass('_item').attr('href', item.reserve_url).append(
                                                        $('<div></div>').addClass('_icon').append(
                                                            $('<div></div>').addClass('SvgUse').addClass('SvgUse--coupon').attr('data-svg','coupon')
                                                        )
                                                    ).append(
                                                        $('<div></div>').addClass('_name').text('クーポン')
                                                    )
                                                )
                                            )
                                        );
                                        
                                        return html;
                                    }

                                    if('child' in val2){
                                        $.each(val2.child, function(index, val3){
                                            tmp_elm.find('.ShopMenuItems').append( push_item(val2.name, val3) );
                                        });
                                    }else{
                                        tmp_elm.find('.ShopMenuItems').append( push_item('', val2) );
                                    }
                                });
                            }
                            htmlElement.append(tmp_elm);
                        });

                        htmlElement.find('[data-svg]').each(function(){
                            var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                            var use_node = document.createElementNS("http://www.w3.org/2000/svg", "use");
                            use_node.setAttributeNS("http://www.w3.org/1999/xlink", "href", "#"+$(this).attr('data-svg'));
                            svg.appendChild(use_node);
                            $(this).append(svg);
                        });

                        htmlElement.find('a').each(function(){
                            var href = $(this).attr('href');
                            if((href===undefined)||(href==='')){
                                $(this).addClass('is-disabled');
                            }
                        });

                        if(initialIndex){
                            htmlElement.find('.AccordionMenuItems__item').eq(initialIndex-1).addClass('is-active');
                        }else{
                            htmlElement.find('.AccordionMenuItems__item').eq(0).addClass('is-active');
                        }

                        elm_inner.html(htmlElement);

                        break;
                    case 'instagram':
                        
                        var data = window.global_menu["shop"];

                        var htmlElement = $('<div></div>').addClass('AccordionMenuItems');

                        $.each(data, function(index, val){
                            var tmp_elm = $('<div></div>').addClass('AccordionMenuItems__item').append(
                                $('<div></div>').addClass('AccordionMenuItems__item-label').attr('onclick','').text(val.name)
                            ).append(
                                $('<div></div>').addClass('AccordionMenuItems__item-inner').append(
                                    $('<div></div>').addClass('ListMenuItems')
                                )
                            );
                            if('child' in val){
                                $.each(val.child, function(index, val2){

                                    var push_item = function(brand_name, items){
                                        var html = $('<div></div>').addClass('ListMenuItems__item').append(
                                            $('<div></div>').addClass('ListMenuItems__title').text(brand_name)
                                        ).append(
                                            $('<div></div>').addClass('ListMenuItems__content').append(
                                                $('<ul></ul>')
                                            )
                                        );

                                        $.each(items, function(index, item){
                                            html.find('ul').append(
                                                $('<li></li>').append(
                                                    $('<a></a>').attr('href', item.instagram_url).text(item.name)
                                                )
                                            );
                                        });
                                        
                                        return html;
                                    }

                                    if('child' in val2){
                                        tmp_elm.find('.ListMenuItems').append( push_item(val2.name, val2.child) );
                                    }else{
                                        tmp_elm.find('.ListMenuItems').append( push_item(val2.name, {val2}) );
                                    }
                                });
                            }
                            htmlElement.append(tmp_elm);
                        });

                        htmlElement.find('a').each(function(){
                            var href = $(this).attr('href');
                            if((href===undefined)||(href==='')){
                                $(this).addClass('is-disabled');
                            }
                        });

                        if(initialIndex){
                            htmlElement.find('.AccordionMenuItems__item').eq(initialIndex-1).addClass('is-active');
                        }else{
                            htmlElement.find('.AccordionMenuItems__item').eq(0).addClass('is-active');
                        }

                        elm_inner.html(htmlElement);

                        break;
                    case 'lineup':

                        var data = window.global_menu["lineup"];

                        var htmlElement = $('<div></div>').addClass('AccordionMenuItems');

                        $.each(data, function(index, val){
                            var tmp_elm = $('<div></div>').addClass('AccordionMenuItems__item').append(
                                $('<div></div>').addClass('AccordionMenuItems__item-label').attr('onclick','').text(val.name)
                            ).append(
                                $('<div></div>').addClass('AccordionMenuItems__item-inner').append(
                                    $('<div></div>').addClass('ListMenuItems')
                                )
                            );
                            if('child' in val){
                                var push_item = function(items){
                                    var html = $('<div></div>').addClass('ListMenuItems__item').append(
                                        $('<div></div>').addClass('ListMenuItems__content').append(
                                            $('<ul></ul>')
                                        )
                                    );

                                    $.each(items, function(index, item){
                                        html.find('ul').append(
                                            $('<li></li>').append(
                                                $('<a></a>').attr('href', item.url).text(item.name)
                                            )
                                        );
                                    });
                                    
                                    return html;
                                }

                                tmp_elm.find('.ListMenuItems').append( push_item(val.child) );
                            }
                            htmlElement.append(tmp_elm);
                        });

                        htmlElement.find('a').each(function(){
                            var href = $(this).attr('href');
                            if((href===undefined)||(href==='')){
                                $(this).addClass('is-disabled');
                            }
                        });

                        if(initialIndex){
                            htmlElement.find('.AccordionMenuItems__item').eq(initialIndex-1).addClass('is-active');
                        }else{
                            htmlElement.find('.AccordionMenuItems__item').eq(0).addClass('is-active');
                        }

                        elm_inner.html(htmlElement);

                        break;
                    case 'recommend':

                        var data = window.global_menu["recommend"];

                        var htmlElement = $('<div></div>').addClass('AccordionMenuItems');

                        $.each(data, function(index, val){
                            var tmp_elm = $('<div></div>').addClass('AccordionMenuItems__item').append(
                                $('<div></div>').addClass('AccordionMenuItems__item-label').attr('onclick','').text(val.name)
                            ).append(
                                $('<div></div>').addClass('AccordionMenuItems__item-inner').append(
                                    $('<div></div>').addClass('ListMenuItems')
                                )
                            );
                            if('child' in val){
                                var push_item = function(items){
                                    var html = $('<div></div>').addClass('ListMenuItems__item').append(
                                        $('<div></div>').addClass('ListMenuItems__content').append(
                                            $('<ul></ul>')
                                        )
                                    );

                                    $.each(items, function(index, item){
                                        html.find('ul').append(
                                            $('<li></li>').append(
                                                $('<a></a>').attr('href', item.url).text(item.name)
                                            )
                                        );
                                    });
                                    
                                    return html;
                                }

                                tmp_elm.find('.ListMenuItems').append( push_item(val.child) );
                            }
                            htmlElement.append(tmp_elm);
                        });

                        htmlElement.find('a').each(function(){
                            var href = $(this).attr('href');
                            if((href===undefined)||(href==='')){
                                $(this).addClass('is-disabled');
                            }
                        });

                        if(initialIndex){
                            htmlElement.find('.AccordionMenuItems__item').eq(initialIndex-1).addClass('is-active');
                        }else{
                            htmlElement.find('.AccordionMenuItems__item').eq(0).addClass('is-active');
                        }
                        
                        elm_inner.html(htmlElement);

                        break;
                    default : 
                        break;
                }
                
                $('body').addClass('is-modalMenuOpened');

                return false;
            });
        });

    }

    init();
})();


/*******************************************
 * 
 *  AccordionMenuItems
 * 
********************************************/

(function() {

    // init
    function init() {
        setup();
    }

    //func
    function setup() {
        $('body').on('click', '.AccordionMenuItems__item-label', function(e){
            if( !$(this).parent('.AccordionMenuItems__item').hasClass('is-active') ){
                $(this).parent().parent().find('.AccordionMenuItems__item.is-active').removeClass('is-active');
                $(this).parent('.AccordionMenuItems__item').addClass('is-active');
            }
        });
    }
    
    init();
})();

/*******************************************
********************************************/
});