require('./common.js');
require('./listmenu.js');
require('./modalmenu.js');
require('./spmenupanel.js');
require('./object_fit_images.js');
require('./top.js');
require('./shop.js');

require('./anime-setAnimation.js');
require('./anime-animeScript.js');
require('./anime-main.js');