const $ = require('jquery');
import { gsap,Linear } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals("ScrollTrigger", ScrollTrigger);

window.tlObjects = [];

$(function(){

'use strict';

/*******************************************
 * 
 * anime classes
 * 
********************************************/

(function() {

    window.setAnimation = function( _elm, _trigger, _tween, _params, _onEnter ){

        if(typeof _onEnter === 'undefined') _onEnter = function(){};

        var elm = _elm;
        var trigger = _trigger;

        var tween = {
            x : 'x' in _tween ? _tween.x : 0,
            y : 'y' in _tween ? _tween.y : 0,
            scale : 'scale' in _tween ? _tween.scale : 1,
            autoAlpha : 'autoAlpha' in _tween ? _tween.autoAlpha : 0,
            duration : 'duration' in _tween ? _tween.duration : 2,
            delay : 'delay' in _tween ? _tween.delay : 0,
            ease : 'ease' in _tween ? _tween.ease : "power4",
        }

        var params = {
            position : 'position' in _params ? _params.position : "top",
            windowPosition : 'windowPosition' in _params ? _params.windowPosition : "center",
            offset : 'offset' in _params ? _params.offset : "+=0",
            markers : 'markers' in _params ? _params.markers : false,
        }

        var tl = gsap.timeline({
            scrollTrigger: {
                trigger: trigger,
                start: params.position+params.offset+" "+params.windowPosition,
                markers: params.markers,
                onEnter: _onEnter,
                //toggleActions: "play pause resume reset", // スクロールを戻したらもう一度開始させる
            }
        });

        tl.from(elm, tween);

        tlObjects.push(tl);
    };

})();

/*******************************************
********************************************/
});
